<template>
  <div
    class="card product-card hover-shadow position-relative"
    style="height: 157px"
  >
    <div class="media p-3 pr-5">
      <!-- Image -->
      <skeleton-loader
        type="square"
        :width="100"
        :height="100"
        :rounded="true"
        animation="fade"
      />
      <!-- ./Image -->

      <div class="media-body ml-3">
        <div class="row align-items-start">
          <div class="col-12 col-md">
            <!-- Product Name -->
            <skeleton-loader
              type="line"
              :width="300"
              :height="15"
              :rounded="true"
              animation="fade"
            />
            <!-- Product Name -->

            <!-- Star -->
            <skeleton-loader
              class="mt-2"
              type="line"
              :width="150"
              :height="15"
              :rounded="true"
              animation="fade"
            />
            <!-- ./Star -->

            <!-- Price -->
            <skeleton-loader
              class="mt-2"
              type="line"
              :width="100"
              :height="30"
              :rounded="true"
              animation="fade"
            />
            <!-- ./Price -->
          </div>
          <div class="col-12 col-md-auto">
            <!-- Badge -->
            <skeleton-loader
              type="square"
              :width="120"
              :height="30"
              :rounded="true"
              animation="fade"
            />
            <!-- ./Badge -->
            <div class="d-flex justify-content-between mt-4">
              <!-- Order -->
              <skeleton-loader
                type="square"
                :width="50"
                :height="50"
                :rounded="true"
                animation="fade"
              />
              <!-- ./Order -->
              <!-- Order -->
              <skeleton-loader
                type="square"
                :width="50"
                :height="50"
                :rounded="true"
                animation="fade"
              />
              <!-- ./Order -->
            </div>
          </div>
          <div class="col-auto">
            <div style="margin-top: 50px">
              <skeleton-loader
                v-for="(i, index) in 3"
                :key="index"
                class="mb-1"
                type="square"
                :width="150"
                :height="15"
                :rounded="true"
                animation="fade"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardLoader",
};
</script>